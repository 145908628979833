import { Action } from '@ngrx/store';
import { type } from '../../../util';
import {
  IPasswordPolicyViewModel
} from '../../../core/services/password-policy/interfaces';

export const ActionTypes = {
  FETCH_PASSWORD_POLICY: type('[Password Policy] fetch password rules'),
  UPDATE_PASSWORD_POLICY: type('[Password Policy] update password rules'),
};

export class FetchPasswordPolicy implements Action {
  public type = ActionTypes.FETCH_PASSWORD_POLICY;

  constructor(public payload?: any) {}
}

export class UpdatePasswordPolicy implements Action {
    public type = ActionTypes.UPDATE_PASSWORD_POLICY;

    constructor(public payload: IPasswordPolicyViewModel) {}
}

export type TypeAction = FetchPasswordPolicy | UpdatePasswordPolicy;
