import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  AbstractServerInteractionService, combineHeadersOptions,
  commonErrorHandlingDisabled
} from '../abstract/abstract.server.interaction.service';
import { Observable} from 'rxjs';
import { Router } from '@angular/router';

import { IPasswordPolicyViewModel } from './interfaces';
import { AppService } from '../../../../app.service';

@Injectable()
export class PasswordPolicyService extends AbstractServerInteractionService {
  protected get url(): string {
    return this.appService.getApiUrls().passwordPolicy;
  }

  constructor(protected appService: AppService,
              protected http: HttpClient,
              protected router: Router) {
    super();
  }

  public getPasswordPolicy(): Observable<IPasswordPolicyViewModel | any> {
    return this.http.get(this.url, { headers: combineHeadersOptions(commonErrorHandlingDisabled)});
  }
}
