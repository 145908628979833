import { createSelector } from '@ngrx/store';
import { IRootState } from '../../../store/index';
import {
  ActionTypes,
  TypeAction
} from './set-password.actions';
import {
  IPasswordRuleViewModel
} from '../../../core/services/password-policy/interfaces';

export interface PasswordRule {
    type: number;
    info: string | null;
    title: string;
    pattern: RegExp;
}

export interface IState {
    passwordRules: PasswordRule[] | null;
}

const initialState: IState = {
    passwordRules: null,
};

export function passwordPolicyReducer(state: IState = initialState, action: TypeAction): IState {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.UPDATE_PASSWORD_POLICY: {
      return Object.assign({}, state, {
          passwordRules: payload.passwordRules.map((ruleViewModel: IPasswordRuleViewModel) => ({
              title: ruleViewModel.title,
              type: ruleViewModel.type,
              info: ruleViewModel.info,
              pattern: RegExp(ruleViewModel.pattern)
          }))
        });
    }
    default: {
      return state;
    }
  }
}

export const getPasswordPolicy = createSelector(
    (rootState: IRootState) => {
      return rootState.passwordPolicy;
    },
    (state: IState): PasswordRule[] => state.passwordRules
  );
